import { inject, Injectable } from '@angular/core';
import { UserState } from '../../shared/user-state/user.state';
import { lastValueFrom } from 'rxjs';
import { UserStateActions } from '../../shared/user-state/user-state.actions';
import { Store } from '@ngxs/store';
import {
  BandmanagerRestApiAppleService,
  BandmanagerRestApiAuthenticationService,
  BandmanagerRestApiGoogleService,
  BandmanagerRestApiTokensResponseDto,
} from '@digitale-menschen/bandmanager-rest-api';
import { Router } from '@angular/router';
import { DeviceState } from '../../shared/device-state/device.state';
import { ProviderResponseMap, SocialLogin } from '@capgo/capacitor-social-login';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Browser } from '@capacitor/browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiSessionService {
  private store = inject(Store);
  private bandmanagerRestApiAuthenticationService = inject(BandmanagerRestApiAuthenticationService);
  private router = inject(Router);
  private isIos = false;
  private apiGoogle = inject(BandmanagerRestApiGoogleService);

  constructor() {
    this.store.select(DeviceState.deviceInfos).pipe(takeUntilDestroyed()).subscribe((deviceInfos) => {
      this.isIos = deviceInfos?.platform === 'ios';
    });
  }

  public async refreshToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const refreshToken = this.store.selectSnapshot(UserState.refreshToken);
      const deviceId = this.store.selectSnapshot(DeviceState.deviceInfos)?.uniqueIdentifier;
      lastValueFrom(this.bandmanagerRestApiAuthenticationService.authenticationControllerRefreshToken({
        body: {
          refreshToken: <string>refreshToken,
          deviceId: <string>deviceId,
        },
      }))
        .then(async (response) => {
          if (response.refresh_token) {
            this.store.dispatch(new UserStateActions.SetRefreshToken((<any>response).refresh_token));
          }
          if (response.access_token) {
            this.store.dispatch(new UserStateActions.SetAuthToken((<any>response).access_token));
          }
          this.store.dispatch(new UserStateActions.SetIsLoggedIn(true));
          resolve(true);
        }).catch((error) => {
        console.error('refresh token error, going to Login', error);
        this.store.dispatch(new UserStateActions.SetIsLoggedIn(false));
        this.router.navigateByUrl('/auth/login').then();
        resolve(false);
      });
    });
  }

  public async authenticateWithGoogle(): Promise<BandmanagerRestApiTokensResponseDto | null> {
    if (this.isIos) {
      await SocialLogin.initialize({
        google: {
          iOSClientId: environment.googleAuth.iOSClientId,
          iOSServerClientId: environment.googleAuth.iOSServerClientId,
        },
      });
    } else {
      console.log('initialize google auth for android/web');
      await SocialLogin.initialize({
        google: {
          webClientId: environment.googleAuth.webClientId,
        },
      });
    }

    console.log('initialized google auth for android/web');
    const response = await SocialLogin.login({
      provider: 'google',
      options: {},
    });
    if (response) {
      console.log('google auth result', response, (response.result as ProviderResponseMap['google']));

      /*
      export interface GoogleLoginResponseOnline {
         accessToken: AccessToken | null
         idToken: string | null
         profile: {
           email: string | null
           familyName: string | null
           givenName: string | null
           id: string | null
           name: string | null
           imageUrl: string | null
         }
         responseType: "online" }
       */

      return await lastValueFrom(this.apiGoogle.googleControllerLogin({ id_token: response.result }));
    } else {
      console.error('error by google-auth');
    }
    return null;
  }

  public async authenticateWithApple(): Promise<void> {
    await Browser.open({ url: `${environment.apiConfiguration.baseUrl}${BandmanagerRestApiAppleService.AppleControllerLoginPath}` });
  }
}
